import React from 'react';
import LeftNavBar from '../components/leftNavBar';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

const useStyles = makeStyles({
  tableHeader: {
    padding: '0 10px',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  tableCell: {
    padding: '0 10px',
  },
});

const Registries = () => {
    const classes = useStyles();
    const registriesData = [
        {
          name: 'Climate Action Reserve (CAR)',
          link: 'https://www.climateactionreserve.org/',
          description: 'Based in the United States, CAR operates one of the most widely recognized carbon offset registries, offering a platform for the registration, issuance, and tracking of carbon offset projects.',
          logo: 'https://www.climateactionreserve.org/wp-content/themes/car/img/reserve-logo.png',
          backgroundColor: '#f7f7f7'
        },
        {
          name: 'Verified Carbon Standard (VCS)',
          link: 'https://verra.org/project/vcs-program/',
          description: 'VCS is a leading global standard for voluntary carbon offset projects. It provides a robust framework for the validation, verification, and certification of carbon credits across various project types and sectors.',
          logo: 'https://verra.org/wp-content/uploads/2022/08/Verra-Logo.svg',
          backgroundColor: '#f7f7f7'
        },
        {
          name: 'Gold Standard',
          link: 'https://www.goldstandard.org/',
          description: 'The Gold Standard is another prominent voluntary carbon offset standard that focuses on ensuring environmental integrity, social responsibility, and sustainable development outcomes in carbon offset projects.',
          logo: 'https://images.prismic.io/goldstandard/64638d52-d9fc-488e-852b-f0cf546a0ce9_Gold+Standard+Logo.svg?q=90',
          backgroundColor: '#333333'
        },
        {
          name: 'American Carbon Registry (ACR)',
          link: 'https://www.americancarbonregistry.org/',
          description: 'ACR is a leading carbon offset registry in North America, providing services for the registration and issuance of carbon credits generated from a wide range of project types.',
          logo: 'https://acrcarbon.org/wp-content/uploads/2023/05/foot_logo.svg',
          backgroundColor: '#142e42'
        },
        {
          name: 'Plan Vivo',
          link: 'https://www.planvivo.org/',
          description: 'Plan Vivo is a community-focused carbon standard that emphasizes sustainable development and poverty alleviation alongside carbon offsetting. It supports projects in developing countries that engage local communities in natural resource management and conservation efforts.',
          logo: 'https://assets.rit.org.uk/small-charity/clients/planvivofoundation/assets/logo.svg',
          backgroundColor: '#f7f7f7'
        },
        { 
            name: 'Social Carbon', 
            link: 'https://www.socialcarbon.org/', 
            description: 'Social Carbon is a standard that focuses on the social and environmental co-benefits of carbon offset projects. It emphasizes community engagement, sustainable development, and poverty reduction alongside carbon sequestration and emission reductions.',
            logo: 'https://images.squarespace-cdn.com/content/v1/6161c89d030b89374bec0b70/825bf801-1513-48b7-8f79-f3ee6b0c9477/SC+transparent+blue+logo.png?format=120w', 
            backgroundColor: '#f7f7f7'
        },
        { 
            name: 'Clean Development Mechanism', 
            link: 'https://cdm.unfccc.int/', 
            description: 'The Clean Development Mechanism (CDM) is an international carbon offset program under the United Nations Framework Convention on Climate Change (UNFCCC). It allows emission-reduction projects in developing countries to earn certified emission reduction (CER) credits, which can be traded and sold, and used by industrialized countries to meet a part of their emission reduction targets under the Kyoto Protocol.',
            logo: 'https://cdm.unfccc.int/style/logo.png', 
            backgroundColor: '#f7f7f7' }
    
      ];

    return (
        <div style={{ display: 'flex'}}>
            <LeftNavBar />
            <div style={{paddingLeft: '20px'}}>
                <h1>Registries</h1>
                <TableContainer style={{ marginLeft: '20px', maxWidth: '95%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeader}>Name</TableCell>
                                <TableCell className={classes.tableHeader}>Link</TableCell>
                                <TableCell className={classes.tableHeader}>Logo</TableCell>
                                <TableCell className={classes.tableHeader}>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {registriesData.map((registry, index) => (
                                <TableRow key={index}>
                                    <TableCell className={classes.tableCell}>{registry.name}</TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <a href={registry.link} target="_blank" rel="noopener noreferrer">
                                            {registry.link}
                                        </a>
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: registry.backgroundColor }} className={classes.tableCell}>
                                        <a href={registry.logo} target="_blank" rel="noopener noreferrer">
                                            <img src={registry.logo} alt={registry.name} style={{ maxHeight: '80px', minHeight: '40px' }} />
                                        </a>
                                    </TableCell>
                                    <TableCell className={`${classes.tableCell}`}>
                                        {registry.description}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default Registries;