import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import './searchBar.css';

const SearchBar = ({ onSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = () => {
        onSearch(searchTerm);
    };

    return (
        <div className="search-bar-container">
            <TextField 
                className="search-bar-input" 
                value={searchTerm} 
                onChange={e => setSearchTerm(e.target.value)}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        handleSearch();
                        e.preventDefault(); // Prevent form submission & page reload
                    }
                }} 
            />
            <Button className="search-bar-button" variant="contained" color="primary" onClick={handleSearch}>
                Search
            </Button>
        </div>
    );
};

export { SearchBar };