import React from 'react';
import { Container, Typography, Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
}));

const CarbonOffsetView = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Paper elevation={3} className={classes.root}>
        <Typography variant="h4" gutterBottom>
          Understanding Carbon Offset Methodologies and Projects
        </Typography>
        <Typography variant="body1" paragraph>
          Carbon offset methodologies and projects are initiatives aimed at reducing or offsetting carbon emissions to mitigate climate change impacts. Here's a breakdown:
        </Typography>
        <Typography variant="h5" gutterBottom>
          Carbon Offset Methodologies
        </Typography>
        <Typography variant="body1" paragraph>
          Carbon offset methodologies are frameworks or guidelines used to quantify and certify carbon emissions reductions or removals. They provide a standardized approach for assessing, monitoring, and verifying emission reduction activities.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Carbon Offset Projects
        </Typography>
        <Typography variant="body1" paragraph>
          Carbon offset projects are specific initiatives that aim to reduce or remove carbon emissions from the atmosphere. These projects can include activities such as reforestation, renewable energy development, methane capture from landfills, and more.
        </Typography>
        <Typography variant="body1" paragraph>
          By investing in carbon offset projects, individuals and organizations can compensate for their carbon footprint by supporting activities that result in equivalent carbon reductions elsewhere.
        </Typography>
        <Typography variant="body1" paragraph>
          It's essential to ensure that carbon offset projects adhere to credible methodologies and undergo rigorous monitoring and verification to ensure their effectiveness in mitigating climate change.
        </Typography>
      </Paper>
    </Container>
  );
};

export default CarbonOffsetView;
