import React from 'react';
import LeftNavBar from '../components/leftNavBar';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

const useStyles = makeStyles({
  tableHeader: {
    padding: '0 10px',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  tableCell: {
    padding: '0 10px',
  },
  tableCellWithPadding: {
    paddingRight: '20px',
  },
});

const StandardsBodies = () => {
    const classes = useStyles();
    const standardsBodies = [
      {
        name: 'International Organization for Standardization (ISO)',
        link: 'https://www.iso.org/',
        description: 'ISO has developed standards related to greenhouse gas emissions, carbon footprinting, and carbon offsetting. ISO 14064 provides guidelines for the quantification and reporting of greenhouse gas emissions, while ISO 14065 specifies requirements for greenhouse gas validation and verification bodies.',
        logo: 'https://www.iso.org/modules/isoorg-template/img/iso/iso-logo-print.gif',
        backgroundColor: '#f7f7f7'
      },
      {
        name: 'The Climate Community and Biodiversity Alliance (CCBA)',
        link: 'https://www.climate-standards.org/',
        description: 'CCBA develops standards for carbon offset projects that integrate climate change mitigation with biodiversity conservation and sustainable development objectives.',
        logo: 'https://www.climate-standards.org/wp-content/themes/frame/images/logo.svg',
        backgroundColor: '#f7f7f7'
      },
      {
        name: 'The Carbon Trust',
        link: 'https://www.carbontrust.com/',
        description: 'The Carbon Trust is a UK-based organization that provides certification and assurance services for carbon reduction initiatives, including carbon footprinting, carbon neutrality, and carbon offset projects.',
        logo: 'https://www.carbontrust.com/themes/custom/carbon_trust/dist/images/logo.svg',
        backgroundColor: '#f7f7f7'
      },
      {
        name: 'The World Resources Institute (WRI)',
        link: 'https://www.wri.org/',
        description: 'WRI collaborates with partners to develop standards and guidelines for greenhouse gas accounting, carbon offsetting, and climate mitigation strategies. It has contributed to the development of the GHG Protocol and other widely recognized carbon accounting frameworks.',
        logo: 'https://www.wri.org/sites/default/files/nav_logo_color_0.svg',
        backgroundColor: '#f7f7f7'
      },
      {
        name: 'The Greenhouse Gas Management Institute (GHGMI)',
        link: 'https://ghginstitute.org/',
        description: 'GHGMI offers training and professional certification programs in greenhouse gas management, including courses on carbon offset project development, carbon accounting principles, and carbon market mechanisms.',
        logo: 'https://ghginstitute.org/wp-content/themes/ghg/img/ghg_logo_color.svg',
        backgroundColor: '#f7f7f7'
      }
    ];

    return (
        <div style={{ display: 'flex'}}>
            <LeftNavBar/>
            <div style={{paddingLeft: '20px'}}>
                <h1>Standards Bodies </h1>
                <TableContainer style={{ marginLeft: '20px', maxWidth: '95%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeader}>Name</TableCell>
                                <TableCell className={classes.tableHeader}>Link</TableCell>
                                <TableCell className={classes.tableHeader}>Logo</TableCell>
                                <TableCell className={classes.tableHeader}>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {standardsBodies.map((standards, index) => (
                                <TableRow key={index}>
                                    <TableCell className={classes.tableCell}>{standards.name}</TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <a href={standards.link} target="_blank" rel="noopener noreferrer">
                                            {standards.link}
                                        </a>
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: standards.backgroundColor }} className={classes.tableCell}>
                                        <a href={standards.logo} target="_blank" rel="noopener noreferrer">
                                            <img src={standards.logo} alt={standards.name} style={{ maxHeight: '80px', minHeight: '60px' }} />
                                        </a>
                                    </TableCell>
                                    <TableCell className={`${classes.tableCell}`}>
                                        {standards.description}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default StandardsBodies;