import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './projectsResultsTable.css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    // ...other styles
    tableContainer: {
      maxHeight: 'calc(100vh - 200px)', // Adjust as needed
    }
  });


const ProjectsResultsTable = ({ results }) => {
    const classes = useStyles();

    const sortKeys = (keys) => {
        const priorityKeys = ['Project Name', 'Project ID'];
    
        return keys.sort((a, b) => {
            if (priorityKeys.includes(a) || priorityKeys.includes(b)) {
                if (a === b) {
                    return 0;
                }
                if (priorityKeys.includes(a)) {
                    return -1;
                }
                if (priorityKeys.includes(b)) {
                    return 1;
                }
            }
    
            const numA = parseInt(a, 10);
            const numB = parseInt(b, 10);
    
            if (isNaN(numA) && isNaN(numB)) {
                return a.localeCompare(b);
            }
            if (isNaN(numA)) {
                return -1;
            }
            if (isNaN(numB)) {
                return 1;
            }
            return numA - numB;
        });
    };

    return (
        <TableContainer component={Paper} className={`${classes.tableContainer} results-table-container`}>
            {Array.isArray(results) && results.length > 0 ? (
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {sortKeys(Object.keys(results[0])).map((key, index) => {
                                if (key !== '_id') {
                                    return <TableCell key={index}><b style={{ color: 'darkblue' }}>{key}</b></TableCell>;
                                }
                                return null;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
                        {results.map((result, index) => (
                            <TableRow key={index}>
                                {sortKeys(Object.keys(result)).map((key, index) => {
                                    if (key !== '_id') {
                                        const value = result[key];
                                        if (key === 'link' || key === 'Registry \nDocuments' || key === 'Project Website') {
                                            return <TableCell key={index}><a href={value}>{value}</a></TableCell>;
                                        }
                                        if (key === 'Project Name' || key === 'Project ID' || key === 'Methodology / Protocol' ) {
                                            return <TableCell key={index}><b>{value}</b></TableCell>;
                                        }
                                        return <TableCell key={index}>{value}</TableCell>;
                                    }
                                    return null;
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <p style={{ paddingLeft: '10px', color: 'red', fontWeight: 'bold' }}>No methodologies found.</p>
            )}
        </TableContainer>
    );
};

export { ProjectsResultsTable as ResultsTable };