import React, { useState } from 'react';
import axios from 'axios';
import { SearchBar } from '../components/searchBar';
import { ResultsTable } from '../components/methodologiesResultsTable';
import '../components/spinner.css';
import LeftNavBar from '../components/leftNavBar';

const Methodologies = () => {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSearch = async (searchTerm) => {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}methodologies?searchTerm=${searchTerm}`, { headers: { 'Content-Type': 'application/json' } });
        console.log(response.data);
        setResults(response.data.record);
        setLoading(false);
    };

    return (
        <div style={{ display: 'flex' }}>
            <LeftNavBar />
            <div style={{margin: '10px'}}>
                <label style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem'}}>Search for GHG Methodologies by "Title"</label>
                <SearchBar onSearch={handleSearch} />
                {loading ? <p>Loading...<br />(This can take a few seconds)</p> : <ResultsTable results={results} />}
            </div>
        </div>
    );
};

export default Methodologies;