import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Methodologies from './views/methodologies';
import Projects from './views/projects';
import Registries from './views/registries';
import StandardsBodies from './views/standardsBodies';
import About from './views/about';
import './global.css';
import LandingPage from './views/landingPage';
import TopNavBar from './components/top-nav-bar'; // Import the TopNavBar component
import { useAuth0 } from '@auth0/auth0-react';
import SubscribePage from './views/subscribePage';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const App = () => {
  const { isAuthenticated, user } = useAuth0();
  //console.log('isAuthenticated', isAuthenticated);
  //console.log('user', user);

  return (
    <Elements stripe={stripePromise}>
      <Router> 
        <div className="app-container">
          <TopNavBar isAuthenticated={isAuthenticated} user={user}/>
          <div className="main-content">
            <Routes>
              <Route path="/" exact element={<LandingPage />} />
              <Route path="/subscribe" element={<SubscribePage />} />
              {isAuthenticated && (
                <>
                  <Route path="/methodologies" exact element={<Methodologies />} />
                  <Route path="/projects" element={<Projects />} />
                  <Route path="/registries" element={<Registries />} />
                  <Route path="/standardsbodies" element={<StandardsBodies />} />
                  <Route path="/about" element={<About />} />
                </>
              )}
            </Routes>
          </div>
        </div>
      </Router>
    </Elements>
  );
};

export default App;