import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const useStyles = makeStyles((theme) => ({
    leftNavBar: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      backgroundColor: '#0565C7', // replace #yourColor with your desired color
      height: '100vh', // set the height to 100% of the viewport height
    },
    button: {
      marginBottom: theme.spacing(1),
      color: theme.palette.primary.contrastText,
      width: '100%', // add this line
    },
    link: {
      textDecoration: 'none', // remove the underline from the links
      display: 'flex',
    },
  }));

const CheckoutForm = () => {
    const classes = useStyles();
    const handleClick = async (event) => {
        // Get Stripe.js instance
        const stripe = await stripePromise;

        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ amount: 499, currency: 'cad' }),
            };
        console.log(request);

        // Call your backend to create the Checkout Session
        const response = await fetch('http://localhost:3030/create-checkout-session', request);
        console.log(response);
        const session = await response.json();

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
        sessionId: session.id,
        });

        if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        console.log(result.error.message);
        }
    };

    return (
        <Button role="link" variant="outlined" color="inherit" onClick={handleClick}>
        Checkout
        </Button>
    );
};

export default CheckoutForm;