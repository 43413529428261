import React, { useState } from 'react';
import axios from 'axios';
import { SearchBar } from '../components/searchBar';
import { ResultsTable } from '../components/projectsResultsTable';
import '../components/spinner.css';
import LeftNavBar from '../components/leftNavBar';

const Projects = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async (searchTerm) => {
    setLoading(true);
    const response = await axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}projects?searchTerm=${searchTerm}`, { headers: { 'Content-Type': 'application/json' } });
    console.log(response.data);
    setResults(response.data.record);
    setLoading(false);
  };

  return (
    <div style={{ display: 'flex' }}>
        <LeftNavBar />
        <div style={{margin: '10px'}}>
          <label style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem'}}>Search for Projects by "Project Name"</label>
          <SearchBar onSearch={handleSearch} />
          {loading ? <p>Loading...<br />(This can take up to 30 seconds due to the size of the data set)</p> : <ResultsTable results={results} />}
      </div>
    </div>
  );
};

export default Projects;