import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: 'calc(100vh - 200px)', // Adjust as needed
  },
});

const MethodologiesResultsTable = ({ results }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={`${classes.tableContainer} results-table-container`}>
      {Array.isArray(results) && results.length > 0 ? (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {Object.keys(results[0]).map((key, index) => {
                if (key !== '_id') {
                  return <TableCell key={index}><b style={{ color: 'darkblue' }}>{key}</b></TableCell>;
                }
                return null;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((result, index) => (
              <TableRow key={index}>
                {Object.entries(result).map(([key, value], index) => {
                  if (key !== '_id') {
                    if (key === 'link') {
                      return <TableCell key={index}><a href={value}>{value}</a></TableCell>;
                    }
                    if (key === 'title') {
                      return <TableCell key={index}><b>{value}</b></TableCell>;
                    }
                    return <TableCell key={index}>{value}</TableCell>;
                  }
                  return null;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <p style={{ paddingLeft: '10px', color: 'red', fontWeight: 'bold' }}>No methodologies found.</p>
      )}
    </TableContainer>
  );
};

export { MethodologiesResultsTable as ResultsTable };