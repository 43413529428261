import React from 'react';
import CheckoutForm from '../components/checkoutForm';
import LeftNavBar from '../components/leftNavBar';

function SubscribePage() {
    return (
        <div style={{ display: 'flex' }}>
        <LeftNavBar />
            <div style={{ margin: '10px', flex: 1 }}>
                <CheckoutForm style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }} />
            </div>
        </div>
    );
}

export default SubscribePage;